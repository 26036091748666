@font-face {
  font-family: Gilroy;
  font-weight: bold;
  src: url(../../Assets/fonts/Gilroy-Bold.ttf)
}
@font-face {
  font-family: Gilroy;
  font-weight: normal;
  src: url(../../Assets/fonts/Gilroy-Medium.ttf)
}
.home-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  font-weight: normal;
  max-width: 480px;
  margin: 1em 0;

  .title {
    font-size: 50px;
    font-weight: bold;
  }

  .description {
    flex-wrap: wrap;
    font-size: small;
    color: grey;
    width: 380px;
    font-size: 14px;
  }

  .button {
    width: fit-content;
    border-radius: 50px;
    display: flex;
    align-items: center;
    color: white;
    padding: 10px 25px;
    cursor: pointer;

    .home-section-btn {
      border: none;
      color: white;
      outline: none;
      font-size: small;
      cursor: pointer;
    }
    .arrow-icon {
      font-size: small;
      margin-left: 8.5px;
    }
  }
}

@media screen and (max-width: 915px) {
  .home-section {
    text-align: center;
    padding: 0 2em;

    .title {
      font-size: 42px;
    }

    .description {
      width: 90%;
      margin: auto;
    }

    .button {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 560px) {
  .home-section {
    .title {
      font-size: 36px;
    }

    .description {
      width: 90%;
      margin: auto;
    }
  }
}
