
.sub_nav{
  display: flex;
  height:5rem;

.navbar {
  background: #FFFFFF;
  padding: 1em 5%;
  font-family: 'Gilroy-Light';
  display: flex;
  justify-content: space-between;
  height:5rem;
  width: 100vw;
  position: fixed;
  z-index: 1;
  a{
    text-decoration: none;
    color: black;
  }
  .navbar__left{
    display: flex;
    align-items: center;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar__right {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .navbar__text {
    margin-right: 20px;
  }

  .navbar__menu:hover {
    cursor: pointer;
  }  
}
.navbar_content{
  a{
    text-decoration: none;
    color: #FFFFFF;
  }
  position: fixed;
  z-index: 2;
  min-height:100vh;
  overflow-y: scroll;
    height:100%;
  width:30vw;
  background-color: #1070ff;
  color: #FFFFFF;
  animation: 0.4s ease-out 0s 1 slideInRight;
  right: 0;
  padding-bottom: 2rem;
  .nav-button-content{
    cursor: pointer;
  }
  .close_icon{
    text-align: end;
    margin: 2rem;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .nav-button-content{
    font-size: 1.5rem;
    margin-bottom: 2rem;margin-left: 3rem;
  }
  .navbar-container_down{
    padding: 2rem;
    display: flex;
    gap:1rem;
  }
  .navbar-container__icons {
    color: white;
    cursor: pointer;
  }
  .navbar-container__social_icons {
    background-color: #003685;
    border-radius: 24px;
    display: flex;
    padding: 12px;
  }
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
.navbar_content_close{
  a{
    text-decoration: none;
    color: #FFFFFF;
  }
  position: fixed;
  z-index: 2;
  min-height:100vh;
  overflow-y: scroll;
    height:100%;
  width:30vw;
  background-color: #1070ff;
  color: #FFFFFF;
  animation: 0.4s ease-out 0s 1 slideInLeft;
  right: 0;
  padding-bottom: 2rem;
  .nav-button-content{
    cursor: pointer;
  }
  .close_icon{
    text-align: end;
    margin: 2rem;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .nav-button-content{
    font-size: 1.5rem;
    margin-bottom: 2rem;margin-left: 3rem;
  }
  .navbar-container_down{
    padding: 2rem;
    display: flex;
    gap:1rem;
  }
  .navbar-container__icons {
    color: white;
    cursor: pointer;
  }
  .navbar-container__social_icons {
    background-color: red;
    border-radius: 24px;
    display: flex;
    padding: 12px;
  }
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
@media(max-width:1400px){
  .navbar_content{
       width:40vw;
  }
  .navbar_content_close{
    width:40vw;
}
}
@media(max-width:900px){
  .navbar_content{
       width:60vw;
       overflow-y: scroll;
  .navbar_content_close{
    width:60vw;
       overflow-y: scroll;
  }
  }
}
@media(max-width:700px){
  .navbar_content{
    width:70vw;
    overflow-y: scroll;
    .nav-button-content{
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      margin-left: 2rem;
    
    }
    .navbar-container_down{
      padding: 0;
      margin-left: 2rem;
    }
}
.navbar_content_close{
  width:70vw;
  overflow-y: scroll;
  .nav-button-content{
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 2rem;
  
  }
  .navbar-container_down{
    padding: 0;
    margin-left: 2rem;
  }
}
}
@media(max-width:500px){
  .navbar_content{
       width:100vw;
       background-color: #1070ff;
       .nav-button-content{
        font-size: 1.5rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
      }
      .navbar-container_down{
        padding: 0;
        margin-left: 0rem;
        justify-content: center;
      }
  }
  .navbar_content{
    width:100vw;
    background-color: #1070ff;
    .nav-button-content{
     font-size: 1.5rem;
     margin-bottom: 2rem;
     margin-left: 2rem;
   }
   .navbar-container_down{
     padding: 0;
     margin-left: 0rem;
     justify-content: center;
   }
}
  
}
}
