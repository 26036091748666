@font-face {
    font-family: Gilroy;
    src: url(../../Assets/fonts/Gilroy-ExtraBold.woff)
}
.Domain-section{
    overflow-x: hidden;
}

.heading{
    margin-left: 100px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: Gilroy;
}
.domains{
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-row-gap: 23px;
    margin-bottom: 50px;
    .domain{
        margin: auto;
        font-family: Gilroy;
        font-size: 19.5px;
        letter-spacing: 1px;
    }
}

@media(max-width: 1486px){
    .domains{
        grid-template-columns: auto auto auto;
        grid-row-gap: 30px;
        .domain{
            margin: auto;
        }
    }
}
@media(max-width: 1089px){
    .heading{
        text-align: center;
        margin-left: 0px;
    }
    .domains{
        grid-template-columns: auto auto;
        grid-row-gap: 30px;
        .domain{
            margin: auto;
        }
    }
}
@media(max-width: 740px){
    .heading{
        text-align: center;
        margin-left: 0px;
    }
    .domains{
        grid-template-columns: auto;
        grid-row-gap: 30px;
        .domain{
            margin: auto;
        }
    }
}

@media(max-width: 696px){
    .heading{
        margin-left: 0px;
        text-align: center;
    }
    
}