.event__container{
    font-family: 'Gilroy-Light';
    overflow-x: hidden;

    .event__top{
        width: 100vw;
        height:12rem;
        background: url("../Assets/images/event_img.png");
        background-size: cover;
        background-position: bottom;
        color: #ffffff;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        padding:3rem;
    }
    .blog__top{
        width: 100vw;
        height:12rem;
        background: url("../Assets/images/blogs-img-1.png");
        background-size: cover;
        background-position: bottom;
        color: #ffffff;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        padding:3rem;
    }
    .project__top{
        width: 100vw;
        height:12rem;
        background: url("../Assets/images/project-img-1.png");
        background-size: cover;
        background-position: bottom;
        color: #ffffff;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        padding:3rem;
    }
    .event__title h1{
        font-weight:700;
        font-family: 'Gilroy-ExtraBold';
        font-size: 32px;
    }
    .project__title h1{
        font-weight:700;
        font-family: 'Gilroy-ExtraBold';
        font-size:2.5rem;
    }

   .blog__tilte_text{
    font-weight:600;
    font-family: 'Gilroy-ExtraBold';
    font-size:3rem;
    margin-bottom:0.3rem;
   }
    .event__search__box{
        display: flex;
        align-items: center;
        padding:3rem;
    }
    .event__search {
        width: 450px;
        height: 45px;
        border: 1px solid rgba(152, 162, 179, 1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        // color: rgba(152, 162, 179, 1);
        color:black;
        font-family: "Gilroy-Light";
        outline: none;
        padding-left:2rem;
        font-size:17px ;
      }
      
      
    .event_btn_filter{
        display: flex;
        align-items: center;
        gap:2rem;
    }
    .event__filter{
        display: flex;
        align-items: center;
    }
    .search-button-btn{
        height: 45px;
        width: 140px;
        margin-left: 20px;
        background-color: rgba(16, 113, 255, 1);
        border: none;
        border-radius: 30px;
        color: white;
        font-size: 15px;
        font-family: 'Gilroy-Light';
        cursor: pointer;
    }
    .event_card{
        padding:1.2rem 3rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content:space-around;
        gap:1.5rem;
    }
    .alumni_card{
        padding:1.2rem 3rem 3rem 3rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content:space-around;
        gap:1.5rem;
    }
    .events_heading_box{
        padding:0rem 3rem;
    }
    .events_heading{
        font-family: 'Gilroy-ExtraBold';
    }
    .alumni_top_box{
        padding:1.2rem 6rem;
        display: flex;
    }
    .alumni_heading{
        width:25%;
    }
    .alumni_para{
        padding-left: 1rem;
        width:75%;
    }
}
@media(min-width:1226px){
    .event_card{
        justify-content: start !important;
    }
}
@media(max-width:954px){
    .alumni_top_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding:1.2rem 2rem !important;
    }
    .alumni_heading{
        width: 75% !important;
        margin-bottom: 1rem !important;
    }
}
@media(max-width:780px){
    .event__search__box{
        flex-direction: column;
        gap:1rem;
    }
    .events_heading_box{
        text-align: center;
    }
}
@media(max-width:450px){
    .event_card{
        width:350px;
    }
    .event__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}


@media(max-width:540px){
    .event__search{
      width: 350px;
    }
  }
