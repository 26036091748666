@font-face {
    font-family: Gilroy;
    font-weight: bold;
    src: url(../../Assets/fonts/Gilroy-Bold.ttf)
  }
  @font-face {
    font-family: Gilroy;
    font-weight: lighter;
    src: url(../../Assets/fonts/Gilroy-Light.woff)
  }
.faq-section{
    overflow-x: hidden;
}

.heading{
    margin-left: 100px;
    font-size: 40px;
    margin-top: 70px;
    font-weight: normal;
}
.faq{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .faq_left{
        width: 600px;
        border: 1.2px solid rgb(181, 181, 181);
        border-radius: 5px;
        .accordian{
            border-bottom: 1px solid black;
            .acc-heading{
                font-weight: bold;
            }
            .acc-desc{
                font-weight: lighter;
            }
        }
    }
    .faq_right{
        width: 600px;
    }
}
@media(max-width: 1316px){
    .faq{      
        .faq_left{
            margin-left: 3rem;
        }
    }
}
@media(max-width: 696px){
    .heading{
        margin-left: 0px;
        text-align: center;
    }
    
    .faq{ 
        margin-top: 30px;
        flex-direction: column;   
        .faq_left{
            margin-left: 0rem;
        }
        .faq_right{
            margin-top: 30px;
        }
    }
}
@media(max-width: 663px){
    .faq{ 
        margin-top: 30px;
        .faq_left{
            width: 300px;
        }
        .faq_right{
            width: 400px;
            margin-left: 20px;
        }
    }
}

