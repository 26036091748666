.abt {
    overflow-x: hidden;
}

.abthead {
    float: left;
    font-size: 45px;
    margin-top: 7rem;
    margin-left: 5rem;
    margin-bottom: 3rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.about_us_full_image {
    height: 42rem;
}

.abtpara {
    text-align: center;
    margin: 4rem;
}

.collage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.collage img {
    width: 100%;

}

@media(max-width:1150px) {
    .collage img {
        width: 35.3rem;
        height: 23rem;
    }

}

@media(max-width:780px) {
    .about_us_full_image {
        height: 24rem;
    }

    .collage img {
        width: 24.4rem;
    }

    h1 {
        font-size: 1.72rem;
        margin-left: 1rem;
    }
}

@media(max-width:550px) {
    .collage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .collage img {
        margin-bottom: 1.2rem;
    }

    .about_us_full_image {
        height: 20rem;
    }

}

@media(max-width:421px) {
    .abthead {
        text-align: center;
        margin: 2rem auto
    }
}