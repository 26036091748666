.Details__container {
  font-family: "Gilroy-Light";
  overflow-x: hidden;
  .blogDetails__top {
    width: 100vw;
    height: 15rem;
    background: url("../Assets/images/blogs-img-1.png");
    background-size: cover;
    background-position: bottom;
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3rem;
  }
  .projectDetails__top {
    width: 100vw;
    height: 15rem;
    background: url("../Assets/images/project-img-1.png");
    background-size: cover;
    background-position: bottom;
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3rem;
  }
  .blogDetails__tilte_text {
    font-weight: 500;
    font-family: "Gilroy-ExtraBold";
    font-size: 3rem;
    margin-bottom: 0.3rem;
  }
  .projectDetails__para_text {
    font-family: "Gilroy-Light";
    font-size: 1rem;
  }
  .Details_card {
    padding: 1.2rem 3rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 1.5rem;
  }
  .details_heading_box {
    padding: 0rem 3rem;
  }
  .details_text_card_box {
    padding: 3rem;
    display: flex;
    gap: 2rem;
  }
  .projectdetails_text_card_box{
    padding: 3rem;
    display: flex;
    gap: 2rem;
  }
  .details__text_1 {
    flex: 5;
  }
  .details__text {
    padding: 2.2rem 3rem;
  }
  .details__text_card {
    flex: 1;
  }
  .details_heading {
    font-family: "Gilroy-ExtraBold";
  }
  .details_para {
    width: 70vw;
    font-family: "Gilroy-Light";
    line-height: 1.5rem;
  }
  .details_mid_img {
    width: 75vw;
    padding: 0rem 3rem;
  }
  .details_mid_img img {
    object-fit: contain;
  }
  .projectdetails_mid_img{
    width: 75vw;
    padding: 0rem 3rem;
  }
  .projectdetails_mid_img img{
    object-fit: contain;
  }

  .project_details_card_title h1 {
    font-size:18px;
    font-family: "Gilroy-ExtraBold";
    font-weight: 400;
  }
  .project_details_card_box {
    margin-top:1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    width:260px;
  }
  .project_details_card {
    width: 120px;
    border-radius: 10px;
    border: none;
    box-sizing: border-box;
    box-shadow: 0.1px 0.25px 5px #c4c4c4;
  }
  .project_card__desc_icon {
    width: "3px";
    text-align: center;
    border-radius: 50%;
    background-color: #003685;
    padding: 0px 1px -0.2px 1px;
  }
  .project_card_icon_text_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media(max-width:1500px){
    .projectdetails_mid_img{
        margin-top:-2rem;
    }
}
@media(max-width:1180px){
    .projectdetails_text_card_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Details__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

}
@media (max-width: 780px) {
  .details_text_card_box {
    display: flex;
    flex-direction: column;
  }
  .details__text_card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .details_heading_box {
    text-align: center;
  }
  .Details__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 450px) {
  .Details_card {
    width: 350px;
  }
  .Details__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
