.main {
  overflow-x: hidden;
  .slide-1 {
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 5%;
    gap: 1em;
    height: 100vh;
  }
  .slide-1-img {
    width:45vw;
  }
  .slide-2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #ffeee1;
    height: 100vh;
  }
  .slide-2-img {
    width: 50vw;
  }
  .achieve_ellipse_images {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
  
    .ellipse1 {
      margin-top: -120px;
    }
  }
  .slide-3 {
    background-color: #00255c;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    margin-top: 10vh;

      .light-box {
        width: 40vw;
      }
    
  }
  .slide-4 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
    height:50vh;
    .email-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 360px;

      h1 {
        margin-bottom: 0.2em;
      }

      .email-input-box {
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid #ccc;
        margin: 1em 0;
        margin-bottom: 0.8em;
        font-size: 14px;
      }
      .logintext {
        color: blue;
        cursor: pointer;
      }
      p {
        font-size: small;
      }
    }
  }
}
@media (max-width: 1024px) {
  .slide-1 {
    height: 80vh;
    padding: 0% 10%;
  }
}

@media (max-width: 915px) {
  .main {
    .slide-1 {
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column-reverse;
      gap: 0;
      text-align: center;

      .button {
        margin: 0 auto;
      }
    }

    .slide-2 {
      flex-direction: column;
      justify-content: space-evenly;
      gap: 1rem;
    }
    .slide-3 {
      flex-direction: column;
      justify-content: space-evenly;
      gap: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    .slide-1-img {
      width:60vw;
    }
    .slide-2-img {
      width: 80vw;
    }
    .achieve_ellipse_images {
      margin-bottom: 1em;

      .ellipse1 {
        margin-top: -120px;
      }
    }
    .slide-2 {
      justify-content: center;
    }
    .slide-4 {
      .email-box {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .main {
    .achieve_ellipse_images {
      margin-bottom: 3em;

      .ellipse1 {
        margin-top: -120px;
      }
    }
    .slide-1{
      height:75vh;
      gap:1.5em;
    }
    .slide-2{
      height:75vh;
    }
    .slide-4{
      height:40vh;
    }
    .slide-3{
      height:75vh;
     
    }
  }
}
