@font-face {
  font-family: Gilroy;
  font-weight: bold;
  src: url(../../Assets/fonts/Gilroy-ExtraBold.woff)
}
@font-face {
  font-family: Gilroy;
  font-weight: normal;
  src: url(../../Assets/fonts/Gilroy-Heavy.ttf)
}
@font-face {
  font-family: Gilroy;
  font-weight: lighter;
  src: url(../../Assets/fonts/Gilroy-Light.woff)
}
.Achievement-section{
  overflow-x: hidden;
}
.achievement-img {
  background-image: url(../../Assets/images/achievement.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 17vw;
  width: 100vw;
  display: flex;
  align-items: center;
  .achievement-heading {
    color: white;
    margin-left: 100px;
    font-size: 3.5vw;
    letter-spacing: 0.7px;
  }
}
.achievement__search__box{
  display: flex;
  align-items: center;
  padding:3rem;
}
.achievement_btn_filter{
  display: flex;
  align-items: center;
  gap:2rem;
}
.achievement__filter{
  display: flex;
  align-items: center;
}

.achievement-cards {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  .radio-button{
    margin-left: 80px;
  }
  .achievement-image {
    margin: 0 20px;
    height: 100px;
    width: 100px;
  }
}
.achievement-details {
  font-weight: normal;
  .achievement-name {
    display: flex;
    align-items: center;
    .achievement-logo {
      height: 30px;
      width: 30px;
    }
  }
}

.search-button-achieve {
  height: 45px;
  width: 140px;
  margin-left: 20px;
  background-color: rgba(16, 113, 255, 1);
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 15px;
  font-family: 'Gilroy-Light';
  cursor: pointer;
}


.search-bar-achieve {
  width: 450px;
  height: 45px;
  border: 1px solid rgba(152, 162, 179, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  // color: rgba(152, 162, 179, 1);
  color:black;
  font-family: "Gilroy-Light";
  outline: none;
  padding-left:2rem;
  font-size:17px ;
}
@media(max-width:540px){
  .search-bar-achieve{
    width: 350px;
  }
}


@media (max-width: 1016px) {
  .achievement-cards {
    padding-right: 100px;
    .radio-button{
        margin-left: 60px;
    }
  }
}
@media (max-width: 665px) {
  .achievement-img {
    height: 130px;
    width: 100vw;
    .achievement-heading {
      margin-left: 0;
      margin: auto;
      font-size: 35px;
    }
  }
}


@media (max-width: 587px) {
    .achievement-cards {
        padding-right: 100px;
        .radio-button{
            margin-left: 40px;
        }
      }
}

@media(max-width:780px){
  .achievement__search__box{
      flex-direction: column;
      gap:1rem;
  }
}  
